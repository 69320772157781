(function()
{
 	var app = angular.module("app");

 	app.controller('JoinController', ['$scope', '$rootScope', '$http', "functions", function($scope, $rootScope, $http, functions)
	{
		$scope.organization 	= {};
		$scope.organizations 	= [];
		$scope.show_logo 		= false;
		$scope.show_picture 	= false;
		$scope.delete_contacts 	= true;
		$scope.requestor 		= {fname: "", lname: "", email: "", phone: "", authority: ""};

		$http.get('/ajaxdata/get_all/tagg?system=1').
			success(function(data, status, headers, config)
			{
				$scope.system_tags = data;
			}).
			error(function(data, status, headers, config)
			{
				error("Error Loading System Tags", data, true, null);
			});

		$scope.claim_profile = function(organization)
		{
			$scope.organization = organization;
		};

    $scope.setResponse = function(response) {
      $scope.gRecaptchaResponse = response;
    }

    $scope.canSave = function() {
      return $scope.claim_organization.$dirty && $scope.claim_organization.$valid && $scope.gRecaptchaResponse;
    };

		$scope.process_claim = function()
		{
			$scope.requestor.organization_id = Number(jQuery("#organization_id").val());
			wait("Sending Request", "This dialog will close automatically.");

			$http(
		    {
		    	method: 'POST',
  			    url: '/members/process_claim',
  			    data: jQuery.param(angular.copy(angular.extend($scope.requestor, { 'g-recaptcha-response': $scope.gRecaptchaResponse }))),
  			    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
  			}).
			success(function(data, status, headers, config)
			{
				success("Your Request Was Sent", "You will be contacted once your request has been approved.", true, function()
		        {
		        	window.location = "/";
		        });
			}).
			error(function(data, status, headers, config)
			{
				error("Error Sending Request", data, true, null);
			});
		};

		$scope.get_matches = function(query)
		{
			// functions.loading();
			return JSON.parse($.ajax({
		        type: "GET",
		        url: '/ajaxdata/get_all/organization?location_id=' + getCookie("location_id") + '&offset=0&limit=1000000&keywords=' + encodeURIComponent(query),
		        async: false
		    }).responseText);
		};
	}]);
})();
